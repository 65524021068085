import React, { useState } from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import PutForm from '../../../api/internal/PutForm';
import Button from '../../../assets/essentials/Button';
import Form from '../../../assets/essentials/Form';
import PostBody from '../../../api/internal/PostBody';

function Details({
  data,
  vendorAssignmentId,
  RefreshPage,
}: {
  data: PayableDetails;
  vendorAssignmentId: Number;
  RefreshPage: Function;
}) {
  const [isQuickBooksPostPending, setIsQuickBooksPostPending] =
    useState<boolean>(false);
    
  function UpdatePayableDetails(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const appendix = {
      vendorAssignmentId: vendorAssignmentId,
    };
    PutForm(`/Accounting/UpdatePayableDetails`, formData, appendix).then(
      () => {
        RefreshPage('Details', ['Summary']);
      },
    );
  }

  const CreateQuickbooksBillIdButton = () => {
    function PostBillToQuickbooks() {
      setIsQuickBooksPostPending(true);
      PostBody('Accounting/PostBillToQuickBooks', {
        vendorAssignmentId: vendorAssignmentId,
      })
      .then(response => {
        if (response) {
          RefreshPage('Details', [, 'Audit']);
        }
      })
      .finally( () => {
        setIsQuickBooksPostPending(false);
      });
    }
    const billHasBeenSentToQuickbooks = data?.quickBooksBillId != null;
    const isDisabled =
      isQuickBooksPostPending ||
      billHasBeenSentToQuickbooks;

    return (
      <div className={`${classes.marginTop05Rem}`}>
        {isQuickBooksPostPending ? (
          <Button disabled>Pending...</Button>
        ) : (
          <Button
            variant="good"
            disabled={isDisabled}
            onClick={() => PostBillToQuickbooks()}
            type="button"
          >
            {billHasBeenSentToQuickbooks
              ? `QuickBooks Bill ID: ${data?.quickBooksBillId}`
              : 'Post Bill To QuickBooks'}
          </Button>
        )}
      </div>
    );
  };
  
  return (
    <>
      <Form onSubmit={e => UpdatePayableDetails(e)}>
        <div className={classes.attribute}>
          <label>Vendor</label>
          <Input disabled type="text" value={data?.vendorName} />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Payment Terms</label>
          <Input disabled type="text" value={data?.paymentTerm} />
        </div>
        <div className={classes.attribute}>
          <label>Bill Date</label>
          <Input type="date" defaultValue={data?.billDate} name="billDate"/>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Due Date</label>
          <Input type="date" defaultValue={data?.dueDate} name="dueDate"/>
        </div>
        <div className={`${classes.attribute}`}>
          <label>QuickBooks Bill ID</label>
          <Input type="number" defaultValue={data?.quickBooksBillId} name="quickBooksBillId"/>
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label className={classes.marginTop05Rem}></label>
          <CreateQuickbooksBillIdButton />
        </div>
        <div>
          <Button type="submit" variant="good" disabled={true}>
            Save Changes
          </Button>
        </div>
      </Form>
    </>
  );
}
export default Details;
