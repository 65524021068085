import React, { ReactNode, useState } from 'react';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import classes from './Accordion.module.css';

/**
 * A collapsible accordion component that toggles content visibility.
 * 
 * @component
 * @param {Object} props - The component props
 * @param {ReactNode | string} [props.title] - Optional title content displayed in header
 * @param {ReactNode} props.children - Content to be shown/hidden inside accordion
 * @param {boolean} [props.defaultActive=false] - Whether accordion is expanded by default
 * 
 * @example
 * <Accordion title="Section Title">
 *   <p>Accordion content here</p>
 * </Accordion>
 * 
 * @example
 * <Accordion 
 *   title={<h2>Complex Title</h2>}
 *   defaultActive={true}
 * >
 *   <div>
 *     <p>Multiple children</p>
 *     <button>Action</button>
 *   </div>
 * </Accordion>
 * 
 * @returns {JSX.Element} Rendered accordion component
 */

type AccordionProps = {
  title?: ReactNode | string;
  children: ReactNode;
  defaultActive?: boolean;
};

const Accordion = ({
  title,
  defaultActive = false,
  children,
}: AccordionProps) => {
  const [isActive, setIsActive] = useState(defaultActive);

  return (
    <div className={`${classes.item} ${isActive ? classes.active : ''}`}>
      <span
        className={classes.title}
        onClick={() => setIsActive(prev => !prev)}
      >
        <span className={`${classes.icon} ${isActive ? classes.isActive : ''}`}>
          <BsChevronUp />
        </span>
        {title ? <span>{title}</span> : null}
      </span>
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default Accordion;
