import TransportationCaller from '../../../api/internal/TransportationCaller';
import React, { useState, useEffect } from 'react';
import MultipleEmailInput from '../../../helpers/inputs/MultipleEmailInput';
import PostBody from '../../../api/internal/PostBody';
import Button, { ButtonStatus } from '../../../assets/essentials/Button';
import Input from '../../../assets/essentials/Input';
import TextArea from '../../../assets/essentials/TextArea';
import classesGlobal from '../../../assets/Global.module.css';
import classes from './CreateInvoice.module.css';
import useTimeout from '../../../hooks/useTimeout';
import Get from '../../../api/internal/Get';
import Create from '../../../helpers/error/Create';
import Error from '../../../helpers/error/Error';
import { LoadingSpinner } from '../../../helpers/loadingSpinner/LoadingSpinner';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';

interface FileName {
  name: string;
  documentType: string;
  fileType: string;
}

interface CreateInvoiceProps {
  data: CustomerInvoiceDetails;
  customerAssignmentId: Number;
  isLoadInvoice: boolean;
  HandlePageChange: Function;
  RefreshPage: Function;
}

function CreateInvoice({
  data,
  customerAssignmentId,
  isLoadInvoice,
  HandlePageChange,
  RefreshPage,
}: CreateInvoiceProps) {
  const [invoiceBlob, setInvoiceBlob] = useState<Blob | null>(null);
  const [fileNames, setFilesNames] = useState<FileName[]>([]);
  const [filesToAttach, setFilesToAttach] = useState<FileName[]>([]);
  const [isFetchingInvoice, setIsFetchingInvoice] = useState<boolean>(false);
  const defaultInvoiceFile: FileName = {
    name: 'invoice',
    documentType: 'invoice',
    fileType: 'pdf',
  };

  useEffect(() => {
    //document types we want returned, ordered by index on backend
    const documentTypes = ['POD', 'Rate Confirmation', 'Unloading Receipts'];
    //file types we want returned, pdfs only for appending via the backend
    const fileTypes = ['pdf'];

    const queryParams = new URLSearchParams();
    documentTypes.forEach(type => queryParams.append('documentTypes', type));
    fileTypes.forEach(type => queryParams.append('fileTypes', type));

    const url = `/File/GetAllFileNames/load-${
      data.loadId
    }?${queryParams.toString()}`;

    Get(url).then(response => {
      if (response) {
        setFilesNames(response.data);
        const fileNamesToAttach = [defaultInvoiceFile, ...response.data];
        setFilesToAttach(fileNamesToAttach);
        isLoadInvoice
          ? GetCustomerLoadInvoice(fileNamesToAttach)
          : GetCustomerRentalInvoice();
      }
    });
    //getcustomerloadinvoice and getcustomerrentalinvoice should be added to dep array but they are causing a rerender loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerAssignmentId, data.loadId]);

  //if no invoice date, early return a message to set an invoice date
  if (!data.invoiceDate) {
    return (
      <>
        <h3>No Invoice Date for this Receivable.</h3>
        <span
          className={classesGlobal.cTA}
          onClick={() => HandlePageChange('Details')}
        >
          Set an Invoice Date.
        </span>
      </>
    );
  }

  //rentals only send invoice with no attachments
  function GetCustomerRentalInvoice() {
    setIsFetchingInvoice(true);
    const path = `/Accounting/GetCustomerRentalInvoice/${customerAssignmentId}`;
    TransportationCaller.get(path, {
      responseType: 'blob',
    })
      .then(response => {
        if (response) {
          const blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          setInvoiceBlob(blob);
        }
      })
      .catch(error => {
        Create(<Error error={error} />);
      })
      .finally(() => setIsFetchingInvoice(false));
  }

  function GetCustomerLoadInvoice(filesToAttach: FileName[]) {
    //sort files by type so backend merges pdfs in order
    function SortFilesBySubtype(a: FileName, b: FileName) {
      const subtypePriority = {
        invoice: 0,
        pod: 1,
        'rate confirmation': 2,
        'unloading receipts': 3,
      };
      const priorityA = subtypePriority[a.documentType.toLowerCase()] ?? 4;
      const priorityB = subtypePriority[b.documentType.toLowerCase()] ?? 4;
      return priorityA - priorityB;
    }

    setIsFetchingInvoice(true);
    const path = `/Accounting/DownloadCustomerLoadInvoice/`;
    const payload = {
      customerAssignmentId: customerAssignmentId,
      fileNamesToAttach: filesToAttach
        .sort(SortFilesBySubtype)
        //backend expects just the file names
        .map(file => file.name),
    };
    TransportationCaller.post(path, payload, {
      responseType: 'blob',
    })
      .then(response => {
        if (response) {
          const blob = new Blob([response.data], {
            type: 'application/pdf',
          });
          setInvoiceBlob(blob);
        }
      })
      .catch(error => {
        Create(<Error error={error} />);
        setIsFetchingInvoice(false);
      })
      .finally(() => setIsFetchingInvoice(false));
  }

  const GenerateInvoiceString = (
    isLoadInvoice: boolean,
    data: CustomerInvoiceDetails,
  ) => {
    const invoiceType = isLoadInvoice ? 'Load' : 'Invoice';
    const invoiceId = isLoadInvoice ? data.loadId : data.rentalId;
    const reference = data.reference ? `Reference: ${data.reference} |` : '';
    return `${reference} BBI ${invoiceType} #${invoiceId}`;
  };

  const invoiceString = GenerateInvoiceString(isLoadInvoice, data);
  const invoiceDate = data.invoiceDate
    ? ` - ${DateOnlyFormatter(data.invoiceDate)}`
    : '';

  const invoiceName = `${invoiceString}${invoiceDate}.pdf`;

  const FileAttachmentTable = ({ invoiceName }) => {
    if (filesToAttach.length === 0) {
      return null;
    }
    const handleChange = (e, selectedFile: FileName) => {
      //need to save updated state to prevent race condition between updating state and calling api
      const updatedFilesToAttach = e.target.checked
        ? [...filesToAttach, selectedFile]
        : filesToAttach.filter(file => file.name !== selectedFile.name);

      setFilesToAttach(updatedFilesToAttach);

      isLoadInvoice
        ? GetCustomerLoadInvoice(updatedFilesToAttach)
        : GetCustomerRentalInvoice();
    };

    //prevent user from unchecking the last file
    const isLastCheckedFile = (selectedFile: FileName) =>
      filesToAttach.length === 1 &&
      filesToAttach.some(file => file.name === selectedFile.name);

    return (
      <section className={`${classes.fileTableContainer}`}>
        <strong>File Attachment</strong> - Select PDF files to attach to email
        <table>
          <thead>
            <tr>
              <th />
              <th>Name</th>
              {fileNames.length > 1 && <th>Type</th>}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={classes.fileTableCheckbox}>
                <input
                  id="invoice"
                  type="checkbox"
                  onChange={e => handleChange(e, defaultInvoiceFile)}
                  checked={filesToAttach.some(
                    file => file.name === defaultInvoiceFile.name,
                  )}
                  disabled={isLastCheckedFile(defaultInvoiceFile)}
                />
              </td>
              <td colSpan={2}>
                <label htmlFor="invoice">{invoiceName}</label>
              </td>
            </tr>
            {fileNames.map(file => {
              const isFileDisabled = isLastCheckedFile(file);
              return (
                <tr key={file.name}>
                  <td className={classes.fileTableCheckbox}>
                    <input
                      id={file.name}
                      type="checkbox"
                      onChange={e => handleChange(e, file)}
                      checked={filesToAttach.includes(file)}
                      disabled={isFileDisabled}
                    />
                  </td>
                  <td>
                    <label htmlFor={file.name}>{file.name}</label>
                  </td>
                  <td>{file.documentType}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </section>
    );
  };

  const CreateQuickbooksInvoiceIdButton = ({
    sendEmailStatus,
    isQuickBooksPostPending,
    setIsQuickBooksPostPending,
  }) => {
    function PostInvoiceToQuickbooks() {
      setIsQuickBooksPostPending(true);
      PostBody('Accounting/PostInvoiceToQuickBooks', {
        customerAssignmentId: customerAssignmentId,
      }).then(response => {
        if (response) {
          RefreshPage('Invoice', ['Details', 'Audit', 'Admin']);
        }
      });
    }
    const invoiceHasBeenSentToQuickbooks = data?.quickBooksInvoiceId != null;

    //prevent user from posting to quickbooks before invoice has been sent
    const isDisabled =
      isQuickBooksPostPending ||
      sendEmailStatus === 'pending' ||
      invoiceHasBeenSentToQuickbooks ||
      isFetchingInvoice;

    return (
      <Button
        variant="good"
        disabled={isDisabled}
        onClick={PostInvoiceToQuickbooks}
        isLoading={isQuickBooksPostPending}
        type="button"
      >
        {invoiceHasBeenSentToQuickbooks
          ? `QuickBooks Invoice ID: ${data?.quickBooksInvoiceId}`
          : 'Post Invoice To QuickBooks'}
      </Button>
    );
  };

  const SendCustomerInvoiceEmailForm = ({ invoiceBlob, invoiceName }) => {
    const [sendEmailStatus, setSendEmailStatus] =
      useState<ButtonStatus>('idle');
    const [isQuickBooksPostPending, setIsQuickBooksPostPending] =
      useState<boolean>(false);
    const [emailRecipients, setEmailRecipients] = useState(
      data?.customerBillingEmails,
    );

    //reset send email button after 10 seconds
    useTimeout(() => {
      if (sendEmailStatus === 'success' || sendEmailStatus === 'failure') {
        setSendEmailStatus('idle');
      }
    }, 10000);

    const HandleSendEmail = e => {
      e.preventDefault();
      setSendEmailStatus('pending');

      //using formdata to send email and attachment blob
      const formData = new FormData(e.target);
      formData.set('toRecipientAddresses', JSON.stringify(emailRecipients));
      formData.append('attachmentName', invoiceName);
      formData.set('customerAssignmentId', customerAssignmentId.toString());
      formData.append('invoiceFile', invoiceBlob);

      TransportationCaller.post('/Customer/SendCustomerInvoice', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          if (response) {
            setSendEmailStatus('success');
            RefreshPage(null, ['Details', 'Audit', 'Summary']);
          } else {
            setSendEmailStatus('failure');
          }
        })
        .catch(error => {
          Create(<Error error={error} />);
          setSendEmailStatus('failure');
        });
    };

    const defaultBody = 'See attached.';

    return (
      <>
        <h2>Send Customer Invoice</h2>
        <form
          onChange={() => setSendEmailStatus('idle')}
          onSubmit={e => HandleSendEmail(e)}
        >
          <div className={`${classesGlobal.attribute} ${classes.container}`}>
            <label htmlFor="toRecipientAddresses">
              To: <span className={classesGlobal.required}>*</span>
            </label>
            <MultipleEmailInput
              submitButtonId={'submit'}
              emails={emailRecipients}
              setEmails={setEmailRecipients}
              id="toRecipientAddresses"
              name="toRecipientAddresses"
              required={emailRecipients.length === 0}
            />
          </div>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span3}`}>
            <label htmlFor="subject">
              Subject: <span className={classesGlobal.required}>*</span>
            </label>
            <Input
              required
              type="text"
              defaultValue={invoiceString}
              id="subject"
              name="subject"
            />
          </div>
          <div className={`${classesGlobal.attribute} ${classesGlobal.span2}`}>
            <label htmlFor="body">Body: </label>
            <TextArea
              required
              defaultValue={defaultBody}
              id="body"
              name="body"
            />
          </div>
          {isLoadInvoice && <FileAttachmentTable invoiceName={invoiceName} />}
          <div className={`${classesGlobal.sideBySideButtons}`}>
            <Button
              type="submit"
              variant="good"
              disabled={
                sendEmailStatus !== 'idle' ||
                isQuickBooksPostPending ||
                isFetchingInvoice
              }
              status={sendEmailStatus}
            >
              Send Email
            </Button>
            <CreateQuickbooksInvoiceIdButton
              isQuickBooksPostPending={isQuickBooksPostPending}
              setIsQuickBooksPostPending={setIsQuickBooksPostPending}
              sendEmailStatus={sendEmailStatus}
            />
          </div>
        </form>
      </>
    );
  };

  const InvoicePDF = () => {
    if (!invoiceBlob || isFetchingInvoice) {
      return (
        <div className={classes.spinnerContainer}>
          <LoadingSpinner />
        </div>
      );
    }
    const pdfUrl = URL.createObjectURL(invoiceBlob);
    return (
      <div className={classesGlobal.pdfContainer}>
        <iframe title="PDF" id="iframe" src={pdfUrl} />
      </div>
    );
  };

  return (
    <>
      <SendCustomerInvoiceEmailForm
        invoiceBlob={invoiceBlob}
        invoiceName={invoiceName}
      />
      <InvoicePDF />
    </>
  );
}
export default CreateInvoice;
