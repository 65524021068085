import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';
import { GiPayMoney } from 'react-icons/gi';
import BBITable from '../../../helpers/bBITable/BBITable';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewVendor from '../../../features/views/vendor/Vendor';
import ViewLoad from '../../../features/views/load/Load';
import ViewPayable from '../../../features/views/payable/Payable';
import CreatePayable from '../../../features/creates/payable/Payable';
import Create from '../../../helpers/panel/Create';
import PostBody from '../../../api/internal/PostBody';
import { useOutletContext } from 'react-router-dom';
import ViewRental from '../../../features/views/rental/Rental';

function OutstandingPayablesBoard({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [payableBoard, setPayableBoard] = useState<Array<PayableBoard>>([]);
  const filterValue = useOutletContext();

  useEffect(() => {
    GetPayables();
  }, [toggleBoardRefresh]);

  function GetPayables() {
    Get(`/Accounting/GetPayableBoard/`).then(response => {
      if (response) {
        setPayableBoard(response.data);
      }
    });
  }

  function FormatPayableBoard(board) {
    return board.map(payable => ({
      ...payable,
      attribute: payable.loadId
        ? payable.loadId
        : payable.rentalId && 'Rental: ' + payable.rentalId,
      dueDate: DateOnlyFormatter(payable.dueDate),
      billDate: DateOnlyFormatter(payable.billDate),
      total: payable.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      remainingBalance: payable.remainingBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  }

  function DownloadPayableReportCSV(payables) {
    let payableIdsToDownload = [];
    payableIdsToDownload = payables?.map(x => x.vendorAssignmentId);
    if (payableIdsToDownload.length > 0) {
      let testArray = { VendorAssignmentIdList: [...payableIdsToDownload] };
      PostBody('Accounting/DownloadPayableReportCSV', testArray).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Payables Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  const tableData = FilterData(FormatPayableBoard(payableBoard), filterValue);

  const columnConfig = [
    {
      key: '1',
      attribute: 'attribute',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Load #',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Reference',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
    },
    {
      key: '3',
      attribute: 'paymentStatus',
      attributeprimaryhidden: 'vendorAssignmentId',
      attributesecondaryhidden: 'loadId',
      attributetertiaryhidden: 'rentalId',
      header: 'Status',
      onClick: e =>
        View(
          <ViewPayable
            vendorAssignmentId={e.target.dataset.attributeprimaryhidden}
            loadId={e.target.dataset.attributesecondaryhidden}
            rentalId={e.target.dataset.attributetertiaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '4',
      attribute: 'vendorName',
      attributeprimaryhidden: 'vendorId',
      header: 'Vendor',
      onClick: e =>
        View(
          <ViewVendor
            vendorName={e.target.innerText}
            vendorId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'quickBooksBillId',
      header: 'QuickBooks Bill ID',
    },
    {
      key: '6',
      attribute: 'billDate',
      header: 'Bill Date',
      dataType: 'date',
    },
    {
      key: '7',
      attribute: 'dueDate',
      header: 'Due Date',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '9',
      attribute: 'remainingBalance',
      header: 'Remaining Balance',
      dataType: 'currency',
    },
  ];

  return (
    <>
      <Header>
        <div>
          <button onClick={() => DownloadPayableReportCSV(tableData)}>
            <MdDownload />
            <span>Download</span>
          </button>
          <button
            onClick={() => Create(<CreatePayable GetPayables={GetPayables} />)}
          >
            <GiPayMoney />
            <span>Create Payables</span>
          </button>
        </div>
      </Header>
      <BBITable data={tableData} columns={columnConfig} />
    </>
  );
}

export default OutstandingPayablesBoard;
