import React, { useState, useEffect, useRef } from 'react';

import Panel from '../../../helpers/panel/Panel';
import PostBody from '../../../api/internal/PostBody';
import Close from '../../../helpers/panel/Close';
import Get from '../../../api/internal/Get';
import Details from './Details';
import Tires from './Tires';
import ViewMaintenanceLog from '../../views/assets/maintenanceLog/MaintenanceLog';
import View from '../../../helpers/slab/View';

export interface MaintenanceLog {
  invoiceNumber: string;
  vendorId: number;
  vendorName: string;
  description: string | null;
  assetName: string;
  assetId: number;
  billDate: Date;
  billingBack: boolean;
  laborCost: number;
  partsCost: number;
  roadsideCost: number;
  miscellaneousCost: number;
  taxesCost: number;
  total: number;
  // Truck tire costs
  truckDriverSideSteerTireCost?: number;
  truckPassengerSideSteerTireCost?: number;
  truckFrontAxleDriverSideInnerTireCost?: number;
  truckFrontAxleDriverSideOuterTireCost?: number;
  truckFrontAxlePassengerSideInnerTireCost?: number;
  truckFrontAxlePassengerSideOuterTireCost?: number;
  truckRearAxleDriverSideInnerTireCost?: number;
  truckRearAxleDriverSideOuterTireCost?: number;
  truckRearAxlePassengerSideInnerTireCost?: number;
  truckRearAxlePassengerSideOuterTireCost?: number;
  // Trailer tire costs
  trailerFrontAxleDriverSideOuterTireCost?: number;
  trailerFrontAxleDriverSideInnerTireCost?: number;
  trailerFrontAxlePassengerSideInnerTireCost?: number;
  trailerFrontAxlePassengerSideOuterTireCost?: number;
  trailerRearAxleDriverSideInnerTireCost?: number;
  trailerRearAxleDriverSideOuterTireCost?: number;
  trailerRearAxlePassengerSideInnerTireCost?: number;
  trailerRearAxlePassengerSideOuterTireCost?: number;
}

export interface OptionLists {
  vendorOptions: VendorOption[];
  assetOptions: AssetOption[];
}

export interface CreateLogOptions {
  assetType: 'truck' | 'trailer';
  includesTires: boolean;
}

const initialLog: MaintenanceLog = {
  invoiceNumber: null,
  vendorId: null,
  vendorName: null,
  description: null,
  assetName: null,
  assetId: null,
  billDate: null,
  billingBack: false,
  laborCost: null,
  partsCost: null,
  roadsideCost: null,
  miscellaneousCost: null,
  taxesCost: null,
  total: null,
};

function CreateMaintenanceLog({ GetLogBoard }) {
  const [optionLists, setOptionLists] = useState<OptionLists>({
    vendorOptions: null,
    assetOptions: null,
  });
  const [createLogOptions, setCreateLogOptions] = useState<CreateLogOptions>({
    includesTires: false,
    assetType: 'truck',
  });
  const maintenanceLog = useRef<MaintenanceLog>({ ...initialLog });

  useEffect(() => {
    Get(`/Asset/GetCreateMaintenanceLog`).then(response => {
      if (response) {
        setOptionLists({
          vendorOptions: response.data.vendorOptions,
          assetOptions: response.data.assetOptions,
        });
      }
    });
  }, []);

  function CreateLog() {
    PostBody('Asset/CreateMaintenanceLog', maintenanceLog.current).then(
      response => {
        if (response) {
          GetLogBoard();
          Close();
          View(<ViewMaintenanceLog maintenanceLogId={response.data} invoiceNumber={maintenanceLog.current.invoiceNumber}/>);
        }
      },
    );
  }

  function sumMaintenanceLogCosts(logRef: React.RefObject<MaintenanceLog>) {
    const log = logRef.current;
    if (!log) return 0;

    const costs = [
      log.laborCost,
      log.partsCost,
      log.roadsideCost,
      log.miscellaneousCost,
      log.taxesCost,
      log.truckDriverSideSteerTireCost,
      log.truckPassengerSideSteerTireCost,
      log.truckFrontAxleDriverSideInnerTireCost,
      log.truckFrontAxleDriverSideOuterTireCost,
      log.truckFrontAxlePassengerSideInnerTireCost,
      log.truckFrontAxlePassengerSideOuterTireCost,
      log.truckRearAxleDriverSideInnerTireCost,
      log.truckRearAxleDriverSideOuterTireCost,
      log.truckRearAxlePassengerSideInnerTireCost,
      log.truckRearAxlePassengerSideOuterTireCost,
      log.trailerFrontAxleDriverSideOuterTireCost,
      log.trailerFrontAxleDriverSideInnerTireCost,
      log.trailerFrontAxlePassengerSideInnerTireCost,
      log.trailerFrontAxlePassengerSideOuterTireCost,
      log.trailerRearAxleDriverSideInnerTireCost,
      log.trailerRearAxleDriverSideOuterTireCost,
      log.trailerRearAxlePassengerSideInnerTireCost,
      log.trailerRearAxlePassengerSideOuterTireCost,
    ];

    const sum = costs.reduce((acc, cost) => {
      const numCost = Number(cost) || 0;
      return acc + numCost;
    }, 0);

    return Number(sum.toFixed(2));
  }

  const pages = [
    {
      title: 'Details',
      displayedIcon: '',
      content: (
        <Details
          log={maintenanceLog.current}
          UpdateLog={UpdateLog}
          createLogOptions={createLogOptions}
          setCreateLogOptions={setCreateLogOptions}
          optionLists={optionLists}
        />
      ),
      require: [
        {
          label: 'Invoice #',
          FunctionBool: () => maintenanceLog.current.invoiceNumber,
          assignedValue: () => maintenanceLog.current.invoiceNumber,
          missingDesc: 'Invoice number required.',
        },
        {
          label: 'Vendor',
          FunctionBool: () => maintenanceLog.current.vendorId,
          assignedValue: () => maintenanceLog.current.vendorName,
          missingDesc: 'Vendor has not been assigned.',
        },
        {
          label: 'Asset',
          FunctionBool: () => maintenanceLog.current.assetId,
          assignedValue: () => maintenanceLog.current.assetName,
          missingDesc: 'Asset has not been assigned.',
        },
        {
          label: 'Total Cost',
          FunctionBool: () => sumMaintenanceLogCosts(maintenanceLog) > 0,
          assignedValue: () =>
            sumMaintenanceLogCosts(maintenanceLog).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            }),
          missingDesc: 'Total Cost must be greater than 0.',
        },
      ],
    },
  ];

  if (createLogOptions.includesTires) {
    pages.push({
      title: 'Tires',
      displayedIcon: '',
      content: (
        <Tires
          log={maintenanceLog.current}
          UpdateLog={UpdateLog}
          assetType={createLogOptions.assetType}
        />
      ),
      require: [],
    });
  }

  function OnSubmit() {
    let fulfillsFormRequirements = true;
    pages.forEach((page, index) => {
      if (!FulFillsPageRequirements(index)) {
        fulfillsFormRequirements = false;
      }
    });
    if (fulfillsFormRequirements) {
      CreateLog();
    } else {
      alert('Not all info included');
    }
  }

  function UpdateLog(newValue: any, attributeName: keyof MaintenanceLog) {
    if (newValue?.toString().trim().length !== 0) {
      (maintenanceLog.current[attributeName] as any) = newValue;
    } else {
      (maintenanceLog.current[attributeName] as any) = null;
    }
  }

  function FulFillsPageRequirements(pageIndex: number) {
    let fulfillsRequirements = true;
    const page = pages[pageIndex];
    //separate check to allow empty requirements for tires component
    if (page && page.require) {
      page.require.forEach(requirement => {
        if (!requirement.FunctionBool()) {
          fulfillsRequirements = false;
        }
      });
    }
    return fulfillsRequirements;
  }

  return (
    <Panel OnSubmit={OnSubmit} title="Create Maintenance Log" pages={pages} />
  );
}

export default CreateMaintenanceLog;
