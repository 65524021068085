import React, { useState, useEffect } from 'react';
import { MdDownload } from 'react-icons/md';
import { GiPayMoney } from 'react-icons/gi';
import BBITable from '../../../helpers/bBITable/BBITable';
import classesCustom from '../../../helpers/search/CustomSearch.module.css';
import DateOnlyFormatter from '../../../helpers/inputs/DateOnlyFormatter';
import FilterData from '../../../helpers/filter/FilterData';
import Get from '../../../api/internal/Get';
import Header from '../../../layouts/Header';
import View from '../../../helpers/slab/View';
import ViewVendor from '../../../features/views/vendor/Vendor';
import ViewLoad from '../../../features/views/load/Load';
import ViewPayable from '../../../features/views/payable/Payable';
import CreatePayable from '../../../features/creates/payable/Payable';
import Create from '../../../helpers/panel/Create';
import Input from '../../../assets/essentials/Input';
import Button from '../../../assets/essentials/Button';
import OptionList from '../../../helpers/options/OptionList';
import Select from '../../../assets/essentials/Select';
import PostBody from '../../../api/internal/PostBody';
import { GenerateQueryString } from '../../../helpers/search/HelperFunctions';
import { useOutletContext } from 'react-router-dom';
import ViewRental from '../../../features/views/rental/Rental';

function OutstandingPayablesBoard({
  toggleBoardRefresh,
}: {
  toggleBoardRefresh: boolean;
}) {
  const [payableSearchFormResults, setPayableSearchFormResults] = useState([]);
  const [paymentStatusOptions, setPaymentStatusOptions] = useState();
  const [searchParams, setSearchParams] = useState<PayableSearchParam>({
    loadId: null,
    rentalId: null,
    paymentStatusId: null,
    vendorName: null,
    billDate: null,
    dueDate: null,
  });
  const filterValue = useOutletContext();

  useEffect(() => {
    Get(`/Accounting/GetPaymentStatusOptions/`).then(response => {
      if (response) {
        setPaymentStatusOptions(response.data);
      }
    });
  }, [toggleBoardRefresh]);

  //Setting searchParams to fill in inputs and dropdowns, then running the search if there is a query parameter
  useEffect(() => {
    if (window.location.search) {
      parseQueryString();
    }
  }, []);

  // Function to update searchParam state based on query string
  const parseQueryString = () => {
    const params = new URLSearchParams(window.location.search);
    const newSearchParams = {};
    for (const [key, value] of params) {
      newSearchParams[key] = value;
    }
    setSearchParams(prevSearchParam => ({
      ...prevSearchParam,
      ...newSearchParams,
    }));
    SearchPayables(window.location.search);
  };

  function SearchPayables(existingQueryString?: string) {
    const queryString = existingQueryString
      ? existingQueryString
      : '?' + GenerateQueryString(searchParams);
    if (!existingQueryString) {
      const newUrl =
        window.location.origin + window.location.pathname + queryString;
      window.history.replaceState(null, null, newUrl);
    }
    Get(`/Accounting/SearchPayables${queryString}`).then(response => {
      if (response) {
        setPayableSearchFormResults(FormatPayableBoard(response.data));
      }
    });
  }

  function HandleSubmit(e) {
    e.preventDefault();
    SearchPayables();
  }

  function UpdateParam(newValue, attributeName) {
    if (newValue?.toString().trim().length !== 0) {
      searchParams[attributeName] = newValue;
    } else {
      searchParams[attributeName] = null;
    }
  }

  function FormatPayableBoard(board) {
    return board.map(payable => ({
      ...payable,
      attribute: payable.loadId
        ? payable.loadId
        : payable.rentalId && 'Rental: ' + payable.rentalId,
      dueDate: DateOnlyFormatter(payable.dueDate),
      billDate: DateOnlyFormatter(payable.billDate),
      total: payable.total.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
      remainingBalance: payable.remainingBalance.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }),
    }));
  }

  const PayableSearchDisplay = () => (
    <BBITable
      data={FilterData(payableSearchFormResults, filterValue)}
      columns={columnConfig}
    />
  );

  function DownloadPayableReportCSV(payables) {
    let payableIdsToDownload = [];
    payableIdsToDownload = payables?.map(x => x.vendorAssignmentId);
    if (payableIdsToDownload.length > 0) {
      let testArray = { VendorAssignmentIdList: [...payableIdsToDownload] };
      PostBody('Accounting/DownloadPayableReportCSV', testArray).then(
        response => {
          if (response) {
            const blob = new Blob([response.data], { type: 'text/csv' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =
              new Date().toISOString().split('T')[0].replace(/[-]/g, '') +
              '_Payables Report.csv';
            a.click();
            URL.revokeObjectURL(url);
          }
        },
      );
    }
  }

  let filteredPayableSearchResults = FilterData(
    payableSearchFormResults || [],
    filterValue,
  );

  let columnConfig = [
    {
      key: '1',
      attribute: 'attribute',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Load #',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
      dataType: 'number',
    },
    {
      key: '2',
      attribute: 'reference',
      attributeprimaryhidden: 'loadId',
      attributesecondaryhidden: 'rentalId',
      header: 'Reference',
      onClick: e =>
        View(
          e.target.dataset.attributeprimaryhidden ? (
            <ViewLoad loadId={e.target.dataset.attributeprimaryhidden} />
          ) : (
            e.target.dataset.attributesecondaryhidden && (
              <ViewRental
                rentalId={e.target.dataset.attributesecondaryhidden}
              />
            )
          ),
        ),
    },
    {
      key: '3',
      attribute: 'paymentStatus',
      attributeprimaryhidden: 'vendorAssignmentId',
      attributesecondaryhidden: 'loadId',
      attributetertiaryhidden: 'rentalId',
      header: 'Status',
      onClick: e =>
        View(
          <ViewPayable
            vendorAssignmentId={e.target.dataset.attributeprimaryhidden}
            loadId={e.target.dataset.attributesecondaryhidden}
            rentalId={e.target.dataset.attributetertiaryhidden}
          />,
        ),
      dataType: 'string',
    },
    {
      key: '4',
      attribute: 'vendorName',
      attributeprimaryhidden: 'vendorId',
      header: 'Vendor',
      onClick: e =>
        View(
          <ViewVendor
            vendorName={e.target.innerText}
            vendorId={e.target.dataset.attributeprimaryhidden}
          />,
        ),
    },
    {
      key: '5',
      attribute: 'quickBooksBillId',
      header: 'QuickBooks Bill ID',
    },
    {
      key: '6',
      attribute: 'billDate',
      header: 'Bill Date',
      dataType: 'date',
    },
    {
      key: '7',
      attribute: 'dueDate',
      header: 'Due Date',
      dataType: 'date',
    },
    {
      key: '8',
      attribute: 'total',
      header: 'Total',
      dataType: 'currency',
    },
    {
      key: '9',
      attribute: 'remainingBalance',
      header: 'Remaining Balance',
      dataType: 'currency',
    },
  ];

  function ClearSearchParams() {
    setSearchParams({
      loadId: null,
      rentalId: null,
      paymentStatusId: null,
      vendorName: null,
      billDate: null,
      dueDate: null,
    });
  }

  const PayableSearchForm = () => {
    return (
      <div
        className={`${classesCustom.customSearch} ${classesCustom.customSearchWithoutForm}`}
      >
        <label>
          Load #
          <Input
            name="loadId"
            defaultValue={searchParams.loadId}
            onChange={e => UpdateParam(e.target.value, 'loadId')}
          />
        </label>
        <label>
          Rental #
          <Input
            name="rentalId"
            defaultValue={searchParams.rentalId}
            onChange={e => UpdateParam(e.target.value, 'rentalId')}
          />
        </label>
        {/* <label>
            Assignment #
            <Input name="vendorAssignmentId" />
          </label> */}
        <label>
          Payment Status
          <Select
            name="paymentStatusId"
            defaultValue={searchParams.paymentStatusId || ''}
            onChange={e => UpdateParam(e.target.value, 'paymentStatusId')}
          >
            <option value="">All Payment Statuses</option>
            <OptionList
              optionData={paymentStatusOptions}
              attributeID="paymentStatusId"
              attributeName="status"
            />
          </Select>
        </label>
        <label>
          Vendor Name
          <Input
            name="vendorName"
            defaultValue={searchParams.vendorName}
            onChange={e => UpdateParam(e.target.value, 'vendorName')}
          />
        </label>
        <label>
          Bill Date
          <Input
            type="date"
            name="billDate"
            defaultValue={searchParams.billDate}
            onChange={e => UpdateParam(e.target.value, 'billDate')}
          />
        </label>
        <label>
          Due Date
          <Input
            type="date"
            name="dueDate"
            defaultValue={searchParams.dueDate}
            onChange={e => UpdateParam(e.target.value, 'dueDate')}
          />
        </label>
        <div className={classesCustom.submitHolder}>
          <Button onClick={e => HandleSubmit(e)} type="submit" variant="good">
            Search
          </Button>

          <Button
            type="button"
            onClick={() => ClearSearchParams()}
            variant="bad"
          >
            Clear
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header>
        <div>
          <button
            onClick={() =>
              DownloadPayableReportCSV(filteredPayableSearchResults)
            }
          >
            <MdDownload />
            <span>Download</span>
          </button>
          <button onClick={() => Create(<CreatePayable />)}>
            <GiPayMoney />
            <span>Create Payables</span>
          </button>
        </div>
      </Header>
      <PayableSearchForm />
      <PayableSearchDisplay />
    </>
  );
}

export default OutstandingPayablesBoard;
