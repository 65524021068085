import React, { useState } from 'react';
import classes from '../../../assets/Global.module.css';
import FinancialTable from '../../../helpers/financialsEditableTable/FinancialsTable';
import PostBody from '../../../api/internal/PostBody';
import View from '../../../helpers/slab/View';
import ViewCustomer from '../customer/Customer';
import ViewVendor from '../vendor/Vendor';
import AssembleUpdatedFinancial from '../../../helpers/financialsEditableTable/AssembleFinancialObject';
import ViewReceivable from '../receivable/Receivable';
import ViewPayable from '../payable/Payable';
import Button from '../../../assets/essentials/Button';
import Select from '../../../assets/essentials/Select';
import OptionList from '../../../helpers/options/OptionList';
import Accordion from '../../../helpers/accordion/Accordion';
import Delete from '../../../api/internal/Delete';

interface RentalFinancialProps {
  data: RentalFinancials;
  rentalId: number;
  RefreshPage: Function;
}

function RentalFinancials({
  data,
  rentalId,
  RefreshPage,
}: RentalFinancialProps) {
  const [isPending, setIsPending] = useState<boolean>(false);

  function CreateAdditionalInvoice() {
    setIsPending(true);
    PostBody(`/rental/CreateRentalInvoice/${rentalId}`, null)
      .then(response => {
        if (response) {
          RefreshPage('Financials', ['Audit', 'Summary']);
        }
      })
      .finally(() => setIsPending(false));
  }

  function CreateRentalBill(rentalId, vendorId) {
    setIsPending(true);
    PostBody(`/rental/CreateRentalBill/${rentalId}/${vendorId}`, null)
      .then(response => {
        if (response) {
          RefreshPage('Financials', ['Audit', 'Summary']);
        }
      })
      .finally(() => setIsPending(false));
  }

  const Invoices = () => {
    const totalReceivableFormatted = data.totalReceivable?.toLocaleString(
      'en-US',
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    );

    const invoiceButtonText =
      data.customerFinancialsList.length > 0
        ? 'Create Additional Invoice'
        : 'Create Invoice';

    const ReceivableSection = ({
      customer,
    }: {
      customer: RentalCustomerAssignmentFinancialObject;
    }) => {
      function SaveDraftReceivable(e, assignmentId) {
        setIsPending(true);
        e.preventDefault();
        PostBody(
          'Accounting/SaveDraftFinancial',
          AssembleUpdatedFinancial(e, assignmentId, 'Customer'),
        ).then(response => {
          if (response) {
            RefreshPage('Financials', ['Audit', 'Summary']);
          }
        });
      }

      const ReceivableLinks = () => {
        return (
          <div className={classes.flexSpaceBetween}>
            <div className={classes.sideBySideButtons}>
              <div
                className={`${classes.cTA}`}
                onClick={() =>
                  View(
                    <ViewCustomer
                      customerId={customer.customerId}
                      customerName={customer.customerName}
                    />,
                  )
                }
              >
                View Customer
              </div>
              <div
                className={`${classes.cTA}`}
                onClick={() =>
                  View(
                    <ViewReceivable
                      customerAssignmentId={customer.customerAssignmentId}
                      loadId={null}
                      rentalId={rentalId}
                    />,
                  )
                }
              >
                View Receivable
              </div>
            </div>
            <div>Status: {customer.paymentStatus}</div>
          </div>
        );
      };

      return (
        <div key={customer.customerAssignmentId}>
          <div className={classes.flexColumn}>
            <h3 className={classes.headerWithoutMargin}>
              {customer.customerName}
            </h3>
            <ReceivableLinks />
          </div>
          <FinancialTable
            rowData={customer.receivables}
            assignmentId={customer.customerAssignmentId}
            assignmentType="Customer"
            accountTypeOptions={customer.receivableAccountTypeOptions}
            onSubmit={SaveDraftReceivable}
          />
        </div>
      );
    };

    const Receivables = ({ customerFinancialsList }) => {
      if (!customerFinancialsList) return null;
      return (
        <>
          {customerFinancialsList.map(
            (customer: RentalCustomerAssignmentFinancialObject) => (
              <ReceivableSection
                key={customer.customerAssignmentId}
                customer={customer}
              />
            ),
          )}
        </>
      );
    };

    return (
      <section className={classes.grayBottomDivider}>
        <Accordion
          defaultActive={data.customerFinancialsList.length > 0}
          title={
            <h2>Invoices - Total Receivable: ${totalReceivableFormatted}</h2>
          }
        >
          <Receivables customerFinancialsList={data.customerFinancialsList} />
        </Accordion>
        <div>
          <Button
            onClick={CreateAdditionalInvoice}
            disabled={isPending}
            variant="good"
          >
            {invoiceButtonText}
          </Button>
        </div>
      </section>
    );
  };

  const Bills = () => {
    const [vendorId, setVendorId] = useState('');

    const totalPayableFormatted = data.totalPayable?.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    const PayableSection = ({
      vendor,
    }: {
      vendor: RentalVendorAssignmentFinancialObject;
    }) => {
      function SaveDraftPayable(e, assignmentId) {
        setIsPending(true);
        e.preventDefault();
        PostBody(
          'Accounting/SaveDraftFinancial',
          AssembleUpdatedFinancial(e, assignmentId, 'Vendor'),
        ).then(response => {
          if (response) {
            RefreshPage('Financials', ['Audit', 'Summary']);
          }
        });
      }

      function DeleteVendorAssignment(e, vendorAssignmentId) {
        e.preventDefault();
        Delete(`Rental/DeleteVendorAssignment/${vendorAssignmentId}`).then(
          response => {
            if (response) {
              RefreshPage('Financials', ['Audit', 'Summary']);
            }
          },
        );
      }

      const PayableLinks = () => {
        return (
          <div className={classes.flexSpaceBetween}>
            <div className={classes.sideBySideButtons}>
              <div
                className={`${classes.cTA}`}
                onClick={() =>
                  View(
                    <ViewVendor
                      vendorId={vendor.vendorId}
                      vendorName={vendor.vendorName}
                    />,
                  )
                }
              >
                View Vendor
              </div>
              <div
                className={`${classes.cTA}`}
                onClick={() =>
                  View(
                    <ViewPayable
                      vendorAssignmentId={vendor.vendorAssignmentId}
                      rentalId={rentalId}
                    />,
                  )
                }
              >
                View Payable
              </div>
            </div>
            <div>Status: {vendor.paymentStatus}</div>
          </div>
        );
      };

      return (
        <div key={vendor.vendorAssignmentId}>
          <div className={classes.flexColumn}>
            <div className={`${classes.flexSpaceBetween}`}>
              <h3>{vendor.vendorName}</h3>
              <Button
                title="Delete line items & save to enable deletion"
                onClick={e =>
                  DeleteVendorAssignment(e, vendor.vendorAssignmentId)
                }
                disabled={vendor.payables.length > 0}
                variant="bad"
              >
                Delete Bill
              </Button>
            </div>
            <PayableLinks />
          </div>
          <FinancialTable
            rowData={vendor.payables}
            assignmentId={vendor.vendorAssignmentId}
            assignmentType="Vendor"
            accountTypeOptions={vendor.payableAccountTypeOptions}
            onSubmit={SaveDraftPayable}
          />
        </div>
      );
    };

    const AllPayables = ({ vendorFinancialsList }) => {
      if (!vendorFinancialsList) return null;
      return (
        <>
          {vendorFinancialsList.map(vendor => (
            <PayableSection key={vendor.vendorAssignmentId} vendor={vendor} />
          ))}
        </>
      );
    };

    const billButtonText =
      data.vendorFinancialsList.length > 0
        ? 'Create Additional Bill'
        : 'Create Bill';

    return (
      <section className={classes.grayBottomDivider}>
        <Accordion
          defaultActive={data.vendorFinancialsList.length > 0}
          title={<h2>Bills - Total Payable: ${totalPayableFormatted}</h2>}
        >
          <AllPayables vendorFinancialsList={data.vendorFinancialsList} />
        </Accordion>
        <span className={classes.attribute}>
          <Select value={vendorId} onChange={e => setVendorId(e.target.value)}>
            <option disabled selected value="">
              -- Select Vendor For Bill --
            </option>
            <OptionList
              optionData={data.vendorOptions}
              attributeID="vendorId"
              attributeName="name"
            />
          </Select>
        </span>
        <Button
          onClick={() => CreateRentalBill(rentalId, vendorId)}
          disabled={isPending || !vendorId}
          variant="good"
        >
          {billButtonText}
        </Button>
      </section>
    );
  };

  return (
    <>
      <Invoices />
      <Bills />
    </>
  );
}

export default RentalFinancials;
