import React from 'react';

import classes from '../../../assets/Global.module.css';
import Input from '../../../assets/essentials/Input';
import Select from '../../../assets/essentials/Select';
import GoogleSearchBoxEstablishmentSingleObject from '../../../helpers/inputs/GoogleSearchBoxEstablishment.js';
import Phone from '../../../helpers/inputs/Phone';
import StatesAndProvinces from '../../../helpers/options/StatesAndProvinces';
import { useRoleAuthorization } from '../../../hooks/useRoleAuthorization';
import { ADMIN_AUTHORIZED_ROLES } from '../../../auth/AuthRoles';

function Details({
  param,
  UpdateParam,
  UpdateVendorForAutoComplete,
  displayBilling,
  ToggleBilling,
}: {
  param: CreateVendorParam;
  UpdateParam: Function;
  UpdateVendorForAutoComplete: Function;
  displayBilling: Boolean;
  ToggleBilling: Function;
}) {
  const CallBackPlace = place => {
    let streetNumber: string;
    let streetName: string;
    let city: string;
    let state: string;
    let zip: string;
    let country: string;
    place.address_components.forEach(component => {
      component.types.forEach(type => {
        if (type === 'locality') {
          city = component.short_name;
        } else if (type === 'administrative_area_level_1') {
          state = component.short_name;
        } else if (type === 'postal_code') {
          zip = component.short_name;
        } else if (type === 'country') {
          country = component.short_name;
        } else if (type === 'street_number') {
          streetNumber = component.short_name;
        } else if (type === 'route') {
          streetName = component.short_name;
        }
      });
    });

    let addressLineOne = streetNumber + ' ' + streetName;
    if (addressLineOne.includes('undefined')) {
      addressLineOne = 'N/A';
    }
    UpdateVendorForAutoComplete(
      place.name,
      addressLineOne,
      city,
      state,
      zip,
      country,
      place.formatted_phone_number,
      place.website,
    );
  };

  const isUserAdmin = useRoleAuthorization(ADMIN_AUTHORIZED_ROLES);

  return (
    <>
      <h1>Details</h1>
      <div className={classes.viewGrid}>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>Auto-Complete</label>
          <GoogleSearchBoxEstablishmentSingleObject
            assignPlace={CallBackPlace}
            onBlur={CallBackPlace}
            required={true}
            id="location"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            Name <span className={classes.required}>*</span>
          </label>
          <Input
            disabled={!isUserAdmin}
            value={param.vendorName}
            onChange={e => UpdateParam(e.target.value, 'vendorName')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>
            Address Line One <span className={classes.required}>*</span>
          </label>
          <Input
            disabled={!isUserAdmin}
            value={param.addressLineOne}
            onChange={e => UpdateParam(e.target.value, 'addressLineOne')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Address Line Two</label>
          <Input
            disabled={!isUserAdmin}
            value={param.addressLineTwo}
            onChange={e => UpdateParam(e.target.value, 'addressLineTwo')}
            type="text"
          />
        </div>
        <div className={classes.attribute}>
          <label>
            City <span className={classes.required}>*</span>
          </label>
          <Input
            disabled={!isUserAdmin}
            value={param.city}
            onChange={e => UpdateParam(e.target.value, 'city')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            State/Province <span className={classes.required}>*</span>
          </label>
          <Select
            value={param.state}
            disabled={!isUserAdmin}
            onChange={e => UpdateParam(e.target.value, 'state')}
          >
            <option value="" />
            <StatesAndProvinces />
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Zip <span className={classes.required}>*</span>
          </label>
          <Input
            disabled={!isUserAdmin}
            value={param.zip}
            onChange={e => UpdateParam(e.target.value, 'zip')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>
            Country <span className={classes.required}>*</span>
          </label>
          <Select
            disabled={!isUserAdmin}
            value={param.country}
            key={param.country}
            onChange={e => UpdateParam(e.target.value, 'country')}
          >
            <option value="" />
            <option value="US">US</option>
            <option value="CA">Canada</option>
          </Select>
        </div>
        <div className={classes.attribute}>
          <label>
            Phone <span className={classes.required}>*</span>
          </label>
          <Phone
            defaultValue={param.phone}
            name="phone"
            onChange={e => UpdateParam(e.target.value, 'phone')}
            expectsEventObject={true}
          />
        </div>
        <div className={`${classes.attribute} ${classes.span2}`}>
          <label>Ext</label>
          <Input
            defaultValue={param.phoneExt}
            onChange={e => UpdateParam(e.target.value, 'phoneExt')}
            type="text"
          />
        </div>
        <div className={`${classes.attribute} ${classes.span3}`}>
          <label>
            <Input
              type="checkbox"
              defaultChecked={displayBilling}
              onChange={ToggleBilling}
            />
            Matches Billing Address
          </label>
        </div>
      </div>
    </>
  );
}

export default Details;
